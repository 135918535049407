<template>
  <div>
    <page-content>
      <h1 style="margin-bottom: 30px">
        账号信息
      </h1>
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="130px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="用户名称"
          prop="userName"
        >
          {{ ruleForm.userName?ruleForm.userName:'--' }}
          <!-- <el-button
            size="small"
            @click="changeMobile"
          >
            修改手机号
          </el-button> -->
        </el-form-item>
        <el-form-item
          label="真实姓名"
          prop="realname"
        >
          <el-input
            v-model="ruleForm.realname"
            class="width360"
            size="mini"
            maxlength="5"
            placeholder="请输入真实姓名"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="关联自提门店"
          prop="selfTemplateId"
        >
          {{ ruleForm.selfTemplateName || '未关联' }}
          <el-button
            type="primary"
            size="small"
            style="display: block;"
            @click="handleCombineShop('selfTemplate')"
          >
            点击关联
          </el-button>
        </el-form-item>
        <el-form-item
          label="关联线下门店"
          prop="offlineShopId"
        >
          {{ ruleForm.offlineShopName || '未关联' }}
          <el-button
            type="primary"
            size="small"
            style="display: block;"
            @click="handleCombineShop('offlineShop')"
          >
            点击关联
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
          >
            保存
          </el-button>
        </el-form-item>
      </el-form>
      <el-dialog
        :title="shopListDialogTitle"
        :visible.sync="isShowShopsDialog"
        width="600px"
        :before-close="() => {
          isShowShopsDialog = false
        }"
      >
        <div v-loading="loading">
          <el-radio-group
            v-model="selectedShop"
          >
            <el-radio
              v-for="(item) in shopList"
              :key="item.id"
              :label="item"
              style="display:flex; align-items: center;"
            >
              <div style=" margin-bottom: 20px;">
                <div style="margin-bottom: 8px;">
                  门店名称：{{ item.name }}
                </div>
                <div style="margin-bottom: 8px;">
                  联系方式：{{ item.phone }}
                </div>
                <div style="margin-bottom: 8px;">
                  门店地址：{{ item.address }}
                </div>
              </div>
            </el-radio>
          </el-radio-group>
        </div>
        <span
          slot="footer"
          class="dialog-footer"
        >
        </span>
      </el-dialog>
    </page-content>
  </div>
</template>

<script>
export default {
  name: 'AccountInfo',
  data() {
    return {
      // 选择门店弹窗
      storeId: '',
      isShowShopsDialog: false,
      shopListDialogTitle: '',
      type: '',
      shopList: [],
      loading: false,
      selectedShop: {

      },

      query: this.$route.query,
      ruleForm: {
        userName: '',
        realname: '',
        offlineShopId: '',
        offlineShopName: '',
        selfTemplateId: '',
        selfTemplateName: ''
      },
      rules: {
        realname: [
          { required: true, message: '请输入真实姓名', trigger: 'change' }
        ]
      },
      roleArr: [

      ]
    }
  },

  watch: {
    'selectedShop': {
      handler: function(val, oldVal) {
        if (this.type === 'offlineShop') {
          this.ruleForm.offlineShopId = val.id
          this.ruleForm.offlineShopName = val.name
        }
        if (this.type === 'selfTemplate') {
          this.ruleForm.selfTemplateId = val.id
          this.ruleForm.selfTemplateName = val.name
        }
      }
    }
  },

  mounted() {
    this.getInfo()
  },
  methods: {
    // 关联线下门店
    handleCombineShop(type) {
      this.type = type
      this.isShowShopsDialog = true
      this.loading = true

      // 线下门店
      if (type === 'offlineShop') {
        this.shopListDialogTitle = '关联线下门店'
        this.$axios
          .get(this.$api.tradeShop.list, {
            params: {

            }
          })
          .then(res => {
            this.loading = false
            if (res.code === 0) {
              const resp = res.data || []
              const data = resp.map((item) => {
                return {
                  id: item.id,
                  name: item.name,
                  phone: item.moblie,
                  address: item.address
                }
              })
              this.shopList = data

              data.forEach((item) => {
                if (item.id === this.ruleForm.offlineShopId) {
                  this.selectedShop = item
                }
              })
            }
          })
          .catch(err => {
            this.loading = false
            console.log(err);
          })
        return
      }

      // 自提门店
      if (type === 'selfTemplate') {
        this.shopListDialogTitle = '关联自提门店'
        this.$axios
          .get(this.$api.trade_template_mail.list, {
            params: {
              templateType: 3,
              storeId: this.storeId
            }
          })
          .then(res => {
            this.loading = false
            if (res.code === 0) {
              const resp = res.data || []
              const data = resp.map((item) => {
                return {
                  id: item.id,
                  name: item.selfName,
                  phone: item.phone,
                  address: item.address
                }
              })
              this.shopList = data

              data.forEach((item) => {
                if (item.id === this.ruleForm.selfTemplateId) {
                  this.selectedShop = item
                }
              })
            }
          })
          .catch(err => {
            this.loading = false
            console.log(err);
          })
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('确定修改账号信息吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              const data = {
                offlineShopId: this.ruleForm.offlineShopId,
                realName: this.ruleForm.realname,
                selfTemplateId: this.ruleForm.selfTemplateId
              }

              this.$axios.post(this.$api.back_supplier.editInfo, data)
                .then(res => {
                  if (res.code === 0) {
                    this.$message.success('修改成功')
                  }
                })
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消'
              })
            })
        } else {
          return false
        }
      })
    },
    // 获取账号信息
    getInfo() {
      this.$axios({
        method: 'post',
        url: this.$api.back_supplier.view
      }).then((res) => {
        if (res.code === 0) {
          const { userMobile, userRealName, relationShopDTO = {}, storeList = [] } = res.data
          this.ruleForm = {
            userName: userMobile,
            realname: userRealName
          }
          const store = storeList[0] || {}

          const storeId = store.storeId
          this.storeId = storeId

          const { offlineShopId, offlineShopName, selfTemplateId, selfTemplateName } = relationShopDTO
          this.ruleForm = {
            ...this.ruleForm,
            offlineShopId,
            offlineShopName,
            selfTemplateId,
            selfTemplateName
          }
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    // 修改手机号
    changeMobile() {
      this.$router.push({ path: '/nb/setting/account/mobile/edit', query: { mobile: this.ruleForm.userName } })
    }
  }
}
</script>
<style scoped>
.width360 {
  width: 360px;
}
</style>
