var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "page-content",
        [
          _c("h1", { staticStyle: { "margin-bottom": "30px" } }, [
            _vm._v(" 账号信息 "),
          ]),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户名称", prop: "userName" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.ruleForm.userName ? _vm.ruleForm.userName : "--"
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "真实姓名", prop: "realname" } },
                [
                  _c("el-input", {
                    staticClass: "width360",
                    attrs: {
                      size: "mini",
                      maxlength: "5",
                      placeholder: "请输入真实姓名",
                    },
                    model: {
                      value: _vm.ruleForm.realname,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "realname", $$v)
                      },
                      expression: "ruleForm.realname",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关联自提门店", prop: "selfTemplateId" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.ruleForm.selfTemplateName || "未关联") +
                      " "
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { display: "block" },
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleCombineShop("selfTemplate")
                        },
                      },
                    },
                    [_vm._v(" 点击关联 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关联线下门店", prop: "offlineShopId" } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.ruleForm.offlineShopName || "未关联") + " "
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { display: "block" },
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleCombineShop("offlineShop")
                        },
                      },
                    },
                    [_vm._v(" 点击关联 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.shopListDialogTitle,
                visible: _vm.isShowShopsDialog,
                width: "600px",
                "before-close": () => {
                  _vm.isShowShopsDialog = false
                },
              },
              on: {
                "update:visible": function ($event) {
                  _vm.isShowShopsDialog = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.selectedShop,
                        callback: function ($$v) {
                          _vm.selectedShop = $$v
                        },
                        expression: "selectedShop",
                      },
                    },
                    _vm._l(_vm.shopList, function (item) {
                      return _c(
                        "el-radio",
                        {
                          key: item.id,
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                          attrs: { label: item },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "20px" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-bottom": "8px" } },
                                [
                                  _vm._v(
                                    " 门店名称：" + _vm._s(item.name) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "margin-bottom": "8px" } },
                                [
                                  _vm._v(
                                    " 联系方式：" + _vm._s(item.phone) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "margin-bottom": "8px" } },
                                [
                                  _vm._v(
                                    " 门店地址：" + _vm._s(item.address) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("span", {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }